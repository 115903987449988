// o_
// sivamayam
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  
  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.GetUser();
  }

  LoginForm = this.fb.group({
    UserID: [''],
    Pwd: ['']
  });

  LoginUser:any;
  GetUser() {
    let Login: any;
    let _lg = localStorage.getItem("Login");
    Login = JSON.parse(_lg);
    this.LoginUser=Login;
   
  };
 

}
