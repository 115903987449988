// o_
// sivamayam
import { Component, OnInit, ViewChild } from '@angular/core';
import { PostHelperService } from '../post-helper.service';

import { Observable, Subject } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

declare var $: any;
@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit {

  constructor(private posthelper: PostHelperService, private http: HttpClient) { }
  WorkList: any;
  WorkGroups: any;

  // mainCategory:any;
  ngOnInit() {
    this.GetWorkList();
    this.GetManpower();
    this.GetVendor();
    this.GetNoteType();
  }


  showTab(tp: string): void {
    if (tp == 'Meterial') {
      //$('#Meterial').tab('show');
      $('#myTab a[href="#Meterial"]').tab('show');
    }
    else if (tp == 'Work') {
      $('#myTab a[href="#Work"]').tab('show');
    }
    else if (tp == 'Work') {
      $('#myTab a[href="#Work"]').tab('show');
    }
    else if (tp == 'Equipment') {
      $('#myTab a[href="#Equipment"]').tab('show');
    }
    else if (tp == 'DRSNotes') {
      // alert(tp);
      $('#myTab a[href="#DRSNotes"]').tab('show');
    }
    else if (tp == 'DRSNotes') {
      // alert(tp);
      $('#myTab a[href="#DRSNotes"]').tab('show');
    }
    else if (tp == 'Manpower') {
      // alert(tp);
      $('#myTab a[href="#Manpower"]').tab('show');
    }
  };
  validteMessage: string = '';
  ValidateSave() {
    if ((this.SelectedWork < 1) || this.SelectedWork == undefined) {
      this.validteMessage = 'Work Not Selected';
      return false;
    }
    if ((!this.isInt(Number(this.Percent)) && !this.isFloat(Number(this.Percent))) || this.Percent == undefined) {
      this.validteMessage = 'Invalid Percentage value';
      return false;
    }
    if (this.Remarks === '' || this.Remarks == undefined) {
      this.validteMessage = 'Remark is empty';
      return false;
    }
    return true;
  };

  ValidateManpowerSave() {
    if ((this.SelectedManpower < 1) || this.SelectedManpower == undefined) {
      this.validteMessage = 'Manpower Not Selected';
      return false;
    }
    if ((this.SelectedVendor < 1) || this.SelectedVendor == undefined) {
      this.validteMessage = 'Vendor Not Selected';
      return false;
    }
    //alert(this.isInt(Number(this.ReportNos)));

    if ((!this.isInt(Number(this.ReportNos)) && !this.isFloat(Number(this.ReportNos))) || this.ReportNos == undefined) {
      this.validteMessage = 'Invalid ReportNos value';
      return false;
    }
    if (this.Reference === '' || this.Reference == undefined) {
      this.validteMessage = 'Reference is empty';
      return false;
    }
    if (this.Billable == undefined) {
      this.Billable = false;
    }
    return true;
  };

  ValidateNote() {
    if ((this.SelectedNoteType < 1) || this.SelectedNoteType == undefined) {
      this.validteMessage = 'NoteType Not Selected';
      return false;
    }

    if (this.NoteBy === '' || this.NoteBy == undefined) {
      this.validteMessage = 'Note By is empty';
      return false;
    }

    if (this.Note === '' || this.Note == undefined) {
      this.validteMessage = 'Note is empty';
      return false;
    }
    if (this.NoteRemarks === '' || this.NoteRemarks == undefined) {
      this.validteMessage = 'Remarks By is empty';
      return false;
    }
    if (this.NoteBy === '' || this.NoteBy == undefined) {
      this.validteMessage = 'Note By is empty';
      return false;
    }
    return true;
  };


  isInt(n) {
    return Number(n) === n && n % 1 === 0;
  };

  isFloat(n) {
    return Number(n) === n && n % 1 !== 0;
  };

  SelectedWork: any;
  Percent: any;
  Remarks: any;
  INSERT_DRSWORKS() {

    if (!this.ValidateSave()) { return; }
    this.validteMessage = '';
    let Login: any;
    let _lg = localStorage.getItem("Login");
    Login = JSON.parse(_lg);
    console.log(this.Percent);
    this.posthelper.INSERT_DRSWORKS(this.SelectedWork, this.Percent,
      this.Remarks, Login.SessionID, this.EditDRS_Work_ID).subscribe(data => {
        alert(data['Result']);
        this.GetDRS('GetDRSWork');
        this.resetWork();
        // console.log('User iD Test');
        // console.log(data);
        // let Result = data['Result'];
        // localStorage.setItem("Login", JSON.stringify(Result));


        // if (Result.ProjectID > 0 && Result.SessionID > 0) {
        // //  this.router.navigate(['/Home']);
        // }

      }, error => {
        console.log(error);
      });
  };


  ClearManpower() {
    this.SelectedManpower = 0;
    this.SelectedVendor = 0;
    this.ReportNos = "";
    this.Reference = "";
    this.Billable = false;

  };
  ReportNos: any;
  Billable: false;
  Reference: '';
  INSERT_DRSManpower() {

    if (!this.ValidateManpowerSave()) { return; }
    this.validteMessage = '';
    let Login: any;
    let _lg = localStorage.getItem("Login");
    Login = JSON.parse(_lg);
    console.log(this.Percent);
    this.posthelper.INSERT_DRSManpower(this.SelectedManpower, this.ReportNos,
      this.SelectedVendor, this.Billable, this.Reference, Login.SessionID, this.EditDRS_MP_ID).subscribe(data => {
        console.log(data['Result']);
        if (data['Result'] == 'UPDATED') {
          this.ClearManpower();
          this.GetDRS('GetDRSManpower');
          //alert("");

        }
        this.restManP();
        alert(data['Result']);
        // console.log('User iD Test');
        // console.log(data);
        // let Result = data['Result'];
        // localStorage.setItem("Login", JSON.stringify(Result));


        // if (Result.ProjectID > 0 && Result.SessionID > 0) {
        // //  this.router.navigate(['/Home']);
        // }

      }, error => {

        console.log(error);
      });
  };

  SelectedNoteType: any;
  NoteRemarks: '';
  Note: '';
  NoteBy: '';
  Image: '';
  fileToUpload: File = null;
  @ViewChild('logoInput', {
    static: true
  }) logoInput;

  INSERT_DRSNotes() {

    if (!this.ValidateNote()) { return; }
    this.validteMessage = '';
    let Login: any;
    let _lg = localStorage.getItem("Login");
    Login = JSON.parse(_lg);
    console.log(this.Percent);

    let formData = new FormData();
    // formData.append('ImageUpload', this.logoInput.nativeElement.files[0]);  
    // formData.append('FileUpload', this.resumeInput.nativeElement.files[0]);  
    formData.append('NoteType', this.SelectedNoteType);
    formData.append('Note', this.Note);
    formData.append('Remarks', this.NoteRemarks);
    formData.append('By', this.NoteBy);
    formData.append('Image', this.logoInput.nativeElement.files[0]);
    formData.append('SESS_ID', Login.SessionID);
    formData.append('DRSNotesNo', this.EditDRS_Note_ID);

    this.posthelper.INSERT_DRSNotes(formData).subscribe(result => {
      alert(result['Result']);
      this.GetDRS('GetDRSNotes');
      this.resetNote();
      console.log(result);
    }, error => {
      console.log(error);
    });

    // this.posthelper.INSERT_DRSNotes(this.SelectedNoteType, this.Note,
    //   this.NoteRemarks ,this.NoteBy,this.Image, Login.SessionID).subscribe(data => {
    //     console.log(data['Result']);
    //    if(data['Result']=='UPDATED')
    //    {
    //     this.ClearManpower();
    //    }
    //     alert(data['Result']);
    //   }, error => {
    //     console.log(error);
    //   });

  };
  // fileToUpload:any;
  imageUrl: any;
  onSelectFile(file: FileList) {
    this.fileToUpload = file.item(0);
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
  }

  LoginUser: any;
  SelectedGroup: any;
  message: string;
  GetWorkList() {
    //this.validteMessage ='Loading...';
    //this.SelectedGroup=0;
    this.message = 'Loading...';
    let Login: any;
    let _lg = localStorage.getItem("Login");
    Login = JSON.parse(_lg);
    this.LoginUser = Login;
    this.posthelper.GetWorkList(Login.ProjectID
    ).subscribe(data => {
      // this.validteMessage ='';
      this.message = '';
      console.log('User iD Test');
      console.log(data);
      this.WorkList = data['Result'];
      this.WorkGroups = Array.from(new Set(this.WorkList.map((item: any) => item.WorkGroup)))

      this.SelectedGroup = this.WorkGroups[0];
      this.SelectedWork = 0;;
    }, error => {
      console.log(error);
    });
  };
  Manpower: any;
  SelectedManpower: any;
  GetManpower() {
    //this.validteMessage ='Loading...';
    //this.SelectedGroup=0;
    this.message = 'Loading...';
    let Login: any;
    let _lg = localStorage.getItem("Login");
    Login = JSON.parse(_lg);
    this.LoginUser = Login;
    this.posthelper.GetManpower('Manpower', Login.SessionID).subscribe(data => {
      // this.validteMessage ='';
      this.message = '';
      console.log('Manpower');
      console.log(data);
      this.Manpower = data['Result'];
      this.SelectedManpower = 0;//this.Manpower[0];
    }, error => {
      console.log(error);
    });
  };

  Vendor: any;
  SelectedVendor: any;
  GetVendor() {
    //this.validteMessage ='Loading...';
    //this.SelectedGroup=0;
    this.message = 'Loading...';
    let Login: any;
    let _lg = localStorage.getItem("Login");
    Login = JSON.parse(_lg);
    this.LoginUser = Login;
    this.posthelper.GetVendor('11', Login.SessionID).subscribe(data => {
      // this.validteMessage ='';
      this.message = '';
      console.log('GetVendor data');
      console.log(data);
      this.Vendor = data['Result'];
      this.SelectedVendor = 0;//this.Manpower[0];
    }, error => {
      console.log(error);
    });
  };


  DRSManPowerRecs: any = [];
  DRSNotesRecs: any = [];
  DRSWorksRecs: any = [];
  SearchDate: any;
  monthShortNames: any = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];
  msgButton: any = ''
  GetDRS(Typ: any) {
    this.DRSManPowerRecs = [];
    // let Typ = 'GetDRSManpower';
    //'GetDRSNotes'
    this.msgButton = 'Loading...';
    let Login: any;
    let _lg = localStorage.getItem("Login");
    Login = JSON.parse(_lg);
    this.LoginUser = Login;

    // alert(this.SearchDate);
    var dt = new Date(this.SearchDate);
    //alert(dt);
    var dtform = `${dt.getDate()} ${this.monthShortNames[dt.getMonth()]} ${dt.getFullYear()}`;

    // alert(dtform);

    this.posthelper.GetDRS(//'10 Nov 2024'
      dtform
      , Typ, Login.SessionID).subscribe(data => {

        this.msgButton = '';
        console.log('GetDRS data');
        console.log(data);
        if (Typ == 'GetDRSManpower') {
          this.DRSManPowerRecs = data['Result'].DRSManPowers;
        }
        else if (Typ == 'GetDRSNotes') {
          this.DRSNotesRecs = data['Result'].DRSNotes;
        }
        else if (Typ == 'GetDRSWork') {
          this.DRSWorksRecs = data['Result'].DRSWorks;
        }
        // this.Vendor = data['Result'];
        // this.SelectedVendor=0;//this.Manpower[0];
      }, error => {
        this.msgButton = '';
        console.log(error);
      });
  };

  SelEdit: any; SelNoteEdit: any; SelWorkEdit: any;
  EditDRS_MP_ID: any = -1; EditDRS_Note_ID: any = -1; EditDRS_Work_ID: any = -1;
  SlEdit(DrsMp: any, typ: any) {
    if (typ == 'GetDRSManpower') {
      this.SelEdit = DrsMp;
      this.SetMPEdit();
    }
    else if (typ == 'GetDRSNotes') {
      this.SelNoteEdit = DrsMp;
      this.SetNoteEdit();
    }
    else if (typ == 'GetDRSWork') {
      this.SelWorkEdit = DrsMp;
      this.SetWorkEdit();
    }
  }

  SetWorkEdit() {
    this.SelectedWork = 0;
    for (var i in this.WorkGroups) {
      if (this.WorkGroups[i].WorkGroupNo == this.SelWorkEdit.WorkGroupNo) {
        this.SelectedGroup = this.WorkGroups[i];
        break;
      }
    }
    // for (var i in this.WorkList) {
    //   if (this.WorkList[i].WorkNo == this.SelWorkEdit.WorkNo) {
    //     this.SelectedWork = this.WorkList[i].WorkNo;
    //     break;
    //   }
    // }
    this.Percent = this.SelWorkEdit.DRSWorkValue;
    this.Remarks = this.SelWorkEdit.Remarks;
    this.EditDRS_Work_ID =this.SelWorkEdit.DRSWorkNo;
  }

  SetMPEdit() {
    for (var i in this.Manpower) {
      if (this.Manpower[i].ComponentNo == this.SelEdit.ManpowerNo) {
        this.SelectedManpower = this.Manpower[i].ComponentNo;
        break;
      }
    }
    for (var i in this.Vendor) {
      if (this.Vendor[i].VendorNo == this.SelEdit.VendorNo) {
        this.SelectedVendor = this.Vendor[i].VendorNo;
        break;
      }
    }
    this.ReportNos = this.SelEdit.reportnos;
    this.Billable = this.SelEdit.billable;
    this.Reference = this.SelEdit.reference;
    this.EditDRS_MP_ID = this.SelEdit.drsManpowerNo;
  }

  SetNoteEdit() {
    for (var i in this.NoteType) {
      if (this.NoteType[i].name == this.SelNoteEdit.notetype) {
        this.SelectedNoteType = this.NoteType[i].name;
        break;
      }
    }

    this.NoteBy = this.SelNoteEdit.by;
    this.Note = this.SelNoteEdit.note;
    this.NoteRemarks = this.SelNoteEdit.remarks;

    this.EditDRS_Note_ID = this.SelNoteEdit.drsnotesNo;;
  }


  CanEdit() {
    this.SelEdit = null;
    this.EditDRS_MP_ID = -1;

    this.SelNoteEdit = null;
    this.EditDRS_Note_ID = -1;

    this.SelWorkEdit = null;
    this.EditDRS_Work_ID = -1;

    this.resetNote();
    this.resetWork();
  }
  restManP() {

    this.SelEdit = null;
    this.EditDRS_MP_ID = -1;

    this.SelectedManpower = 0;
    this.SelectedVendor = 0;
    this.EditDRS_MP_ID = -1;
    this.EditDRS_Note_ID = -1;
  }

  resetNote() {

    this.SelNoteEdit = null;
    this.EditDRS_Note_ID = -1;

    this.SelectedNoteType = '0';
    this.NoteBy = '';
    this.Note = '';
    this.NoteRemarks = '';
  }

  resetWork() {

    this.SelWorkEdit = null;
    this.EditDRS_Work_ID = -1;
    this.SelectedGroup = this.WorkGroups[0];

    this.SelectedWork = 0;
    this.Percent = '';
    this.Remarks = '';
  }


  NoteType: any;
  //SelectedNoteType :0;
  GetNoteType() {
    this.http.get("assets/NotesType.json").subscribe(data => {
      console.log(data);
      this.NoteType = data;
      this.SelectedNoteType = 0;
    })


    // this.posthelper.GetNoteType(
    // ).subscribe(data => {
    //  // this.validteMessage ='';
    //  this.message='';
    //   console.log('GetVendor data');
    //   console.log(data);
    //   this.Vendor = data['Result'];
    //   this.SelectedVendor=0;//this.Manpower[0];
    // }, error => {
    //   console.log(error);
    // });
  };

  filterSubByIGroup(id) {
    if (this.WorkList == undefined) {
      return;
    }
    //this.SelectedWork=0;
    return this.WorkList.filter(item => item.WorkGroup === id);
  }


}
