// o_
// sivamayam
import { Component, OnInit } from '@angular/core';
import { PostHelperService } from '../post-helper.service'
//import { FormBuilder, FormGroup } from "@angular/forms";
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { FormArray } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  //form: FormGroup;
  // constructor(public fb: FormBuilder,private posthelper: PostHelperService) { 
  //   this.form = this.fb.group({
  //     name: [''],
  //     avatar: [null]
  //   })
  // }
  constructor(private posthelper: PostHelperService, private fb: FormBuilder,private router: Router) { }
  ngOnInit() {
  }
  LoginForm = this.fb.group({
    UserID: ['', Validators.required],
    Pwd: ['', Validators.required]
  });

  USER_NAME: string = '';
  PWD: string = '';
  message:string='';
  BAEVR_INSERT_SESSION() {
    this.message='Proccesing...';
    this.posthelper.BAEVR_INSERT_SESSION(this.LoginForm.value.UserID,
      this.LoginForm.value.Pwd).subscribe(data => {
        console.log('User iD Test');
        console.log(data);
        let Result = data['Result'];
        

//         var test = { test: "thing", test2: "thing2", test3: [0, 2, 44] }​​​​​​​;
// localStorage.setItem("test", JSON.stringify(test));
// var test2 = localStorage.getItem("test");
// test = JSON.parse(test2); //var test 

        if (Result.ProjectID > 0 && Result.SessionID > 0) {
          Result.UserName=this.LoginForm.value.UserID;
          localStorage.setItem("Login", JSON.stringify(Result));
          this.router.navigate(['/Home']);
        }
        else
        {
          this.message='invalid password or user name';
        }

      }, error => {
        console.log(error);
      });
  };

  submitForm() {
    // console.log(this.form.value)
  }

}
