// o_
// sivamayam
import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../environments/environment';

import { HttpHeaders } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import data from '../assets/Api.json';
//const API_URL = environment.apiUrl;
const API_URL = data.API_URL;
@Injectable({
  providedIn: 'root'
})
export class PostHelperService {

  constructor(private http: HttpClient, private route: ActivatedRoute) { }
  //constructor() { }

  BAEVR_INSERT_SESSION(USER_NAME, PWD) {
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    let postData = {
      "test": "Customer004"
    }
    const myheader = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    let body = new HttpParams();

    body = body.set('USER_NAME', USER_NAME);
    body = body.set('PWD', PWD);

    return this.http.post(API_URL + "/api/Default/BAEVR_INSERT_SESSION"
      //return this.http.post(API_URL + "/api/Default/GetMinorByMajor"
      , body
      , { headers: myheader }
    )
  };

  INSERT_DRSWORKS(WORK_NO, PERCENTAGE, REMARK,
    SESS_ID,DRSWorkNo) {
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    let postData = {
      "test": "Customer004"
    }
    const myheader = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    let body = new HttpParams();

    body = body.set('WORK_NO', WORK_NO);
    body = body.set('PERCENTAGE', PERCENTAGE);

    body = body.set('REMARK', REMARK);
    body = body.set('SESS_ID', SESS_ID);
    body = body.set('DRSWorkNo', DRSWorkNo);

    return this.http.post(API_URL + "/api/Default/INSERT_DRSWORKS"
      //return this.http.post(API_URL + "/api/Default/GetMinorByMajor"
      , body
      , { headers: myheader }
    )
  };


  INSERT_DRSManpower(Manpower, ReportNos, Vendor,
    Billable, Reference,
    SESS_ID,DRSManpowerNo) {
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    let postData = {
      "test": "Customer004"
    }
    const myheader = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    let body = new HttpParams();

    body = body.set('Manpower', Manpower);
    body = body.set('ReportNos', ReportNos);
    body = body.set('Vendor', Vendor);
    body = body.set('Billable', Billable);
    body = body.set('Reference', Reference);

    body = body.set('SESS_ID', SESS_ID);
    
    body = body.set('DRSManpowerNo', DRSManpowerNo);

    return this.http.post(API_URL + "/api/Default/INSERT_DRSManpower"
      //return this.http.post(API_URL + "/api/Default/GetMinorByMajor"
      , body
      , { headers: myheader }
    )
  };

  // INSERT_DRSNotes(NoteType, Note, Remarks, By, Image,
  //  SESS_ID) {
  // var headers = new Headers();
  // headers.append("Accept", 'application/json');
  // headers.append('Content-Type', 'application/json');
  // let postData = {
  // "test": "Customer004"
  // }
  // const myheader = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
  // let body = new HttpParams();

  // body = body.set('NoteType', NoteType);
  // body = body.set('Note', Note);
  // body = body.set('Remarks', Remarks);
  // body = body.set('By', By);
  // body = body.set('Image', Image);

  // body = body.set('SESS_ID', SESS_ID);

  // return this.http.post(API_URL + "/api/Default/INSERT_DRSNotes"
  // , body
  // , { headers: myheader }
  // )
  // };

  INSERT_DRSNotes(data: FormData): Observable<string> {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const httpOptions = {
      headers: headers
    };
    return this.http.post<string>(API_URL + '/api/Default/INSERT_DRSNotes', data, httpOptions);
  }

  GetWorkList(ProjectID) {
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    let postData = {
      "test": "Customer004"
    }
    const myheader = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    let body = new HttpParams();

    body = body.set('ProjectID', ProjectID);

    return this.http.post(API_URL + "/api/Default/GetWorkList"
      , body
      , { headers: myheader }
    )
  };

  GetManpower(type, SESS_ID) {
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    let postData = {
      "test": "Customer004"
    }
    const myheader = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    let body = new HttpParams();
    body = body.set('type', type);
    body = body.set('SESS_ID', SESS_ID);
    return this.http.post(API_URL + "/api/Default/GetManpower"
      , body
      , { headers: myheader }
    )
  };

  GetVendor(type, SESS_ID) {
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    let postData = {
      "test": "Customer004"
    }
    const myheader = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    let body = new HttpParams();
    body = body.set('type', type);
    body = body.set('SESS_ID', SESS_ID);
    return this.http.post(API_URL + "/api/Default/GetVendor"
      , body
      , { headers: myheader }
    )
  };




  GetDRS(DRSDate, DrsType, SESS_ID) {
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    let postData = {
      "test": "Customer004"
    }
    const myheader = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    let body = new HttpParams();
    body = body.set('DRSDate', DRSDate);
    body = body.set('DrsType', DrsType);

    body = body.set('SESS_ID', SESS_ID);
    return this.http.post(API_URL + "/api/Default/GetDRS"
      , body
      , { headers: myheader }
    )
  };


  GetNoteType() {
    // var headers = new Headers();
    // headers.append("Accept", 'application/json');
    // headers.append('Content-Type', 'application/json');
    // let postData = {
    //   "test": "Customer004"
    // }
    // const myheader = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    // let body = new HttpParams();
    // body = body.set('type', type);
    //  return this.http.post(API_URL + "/api/Default/GetVendor"
    //   , body
    //   , { headers: myheader }
    // )

    this.http.get("assets/NotesType.json").subscribe(data => {
      // console.log(data);
      // this.products = data;
      return data;
    })
  };


}
