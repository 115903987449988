// o_
// sivamayam
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { HomePageComponent } from './home-page/home-page.component';
import { BuildingEstimateComponent } from './building-estimate/building-estimate.component';
const routes: Routes = [
  { path: '', redirectTo: 'Login', pathMatch: 'full' },
  { path: 'Login',component:LoginComponent },
  { path: 'Home', component:HomePageComponent},
  { path: 'Estimate', component:BuildingEstimateComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
